<template>
  <div>
    <v-row
      class="mt-5 justify-end"
      align="end"
    >
      <v-col v-if="userAccess.canCreate && $route.query.status === 'NEED_CONFIRMATION'" cols="auto" class="text-end">
        <v-btn
          depressed
          color="warning"
          min-width="200"
          @click="createOrder"
        >
          {{ $_strings.statusOrder.RETURN_ITEM }}
        </v-btn>
      </v-col>
      <v-col v-if="userAccess.canUpdate && $route.query.status === 'NEED_CONFIRMATION'" cols="auto" class="text-center">
        <v-btn
          depressed
          color="primary"
          min-width="200"
          @click="baspConfirmation"
        >
          {{ $_strings.statusOrder.RECEIVED_WAREHOUSE }}
        </v-btn>
      </v-col>
      <v-col
        v-if="showBtnCancelOrder"
        cols="12"
        sm="5"
        md="3"
      >
        <v-btn
          block
          color="red"
          class="white--text"
          @click="$refs.dialogCancelOrder.dialog = true"
        >
          {{ $_strings.statusOrder.CANCEL_ORDER }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- DIALOG SUCCESS -->
    <v-dialog
      v-model="dialogSuccessBasp"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-text class="d-flex flex-column align-center">
          <div class="mt-4 mb-4">
            <v-icon x-large color="green" >mdi-checkbox-marked-circle-outline</v-icon>
          </div>
          <p class="text-h5">{{$_strings.statusOrder.SUCCESS}}</p>
          <p class="grey--text">{{$_strings.statusOrder.SUCCESS_RETURN_ITEM}}</p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary"
            @click="navigateToListProcessOrder"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogCancelOrder
      ref="dialogCancelOrder"
    />
  </div>
</template>

<script>
import { getDateTimeTz } from '@/helper/commonHelpers';
import DialogCancelOrder from '../Dialog/CancelOrder';

export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogCancelOrder,
  },
  data() {
    return {
      dialogSuccessBasp: false,
    };
  },
  computed: {
    showBtnCancelOrder() {
      const { latestStatus } = this.shipment;
      const _status = [
        'terkonfirmasi',
        'menunggu konfirmasi driver',
        'driver waktu habis',
        'pesanan dibuat',
        'menunggu konfirmasi',
        'menunggu konfirmasi transporter',
        'waktu habis',
      ];
      if (_status.includes(latestStatus.toLowerCase())) return true;
      return false;
    },
  },
  methods: {
    createOrder() {
      this.$router.push({
        name: 'create',
        params: {
          order: this.shipment,
          isBasp: 'basp',
        },
      });
    },
    navigateToListProcessOrder() {
      this.dialogSuccessBasp = true;
      this.$router.replace('/main/status-pesanan/list/process');
    },
    async baspConfirmation() {
      const { id } = this.$route.params;
      const data = {
        status: 'BA_PENERIMAAN_DITERIMA_GUDANG',
        notes: null,
        statusDatetimeTz: getDateTimeTz(),
      };
      try {
        this.$root.$loading.show();
        await this.$_services.order.baspConfirmation(id, data);
        this.dialogSuccessBasp = true;
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
